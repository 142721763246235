.contailner__pageheader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex__content__top {
    display: flex;
    gap: 20px;
    align-items: center;
}

.title__component__pageheader {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    background-color: transparent;
    border-radius: 8px;
}

.label__date__name {
    color: #747676;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 3rem;
}

@media screen and (max-width: 800px) {
    .flex__content__top {
        gap: 5px;
    }

    .title__component__pageheader {
        font-size: 16px;
    }

    .label__date__name {
        font-size: 14px;
        margin-top: 0px;
        margin-left: 2rem;
    }
}