.listHover{
    padding-left: 2.8rem;
    transition: 0.3s ease;
}
.listHover:hover{
    padding-left: 0;
    transition: 0.3s ease;
}
@media only screen and (max-width: 768px) {
    .listHover{
        padding-left: 0;
    }
  }