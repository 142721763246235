.overlay {
    z-index: 11000;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(28, 28, 28, 0.40);
}

.modalController {
    width: 550px;
    height: auto;
    background: #ffffff;
    border-radius: 9px;
    margin: 0 1rem;
    padding: 1rem;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
}

.closeButton img {
    width: 24px;
    height: 24px;
}

.closeButton img:hover {
    cursor: pointer;
}

.contentModal {
    margin-top: -10px;
    padding: 1rem;
    display: grid;
    justify-items: flex-start;
}

.label__title__modal {
    font-size: 24px;
    font-weight: 500;
}

.label__sub__text {
    color: #747676;
    font-weight: 500;
}

.imgProfile {
    width: 70px;
    border-radius: 50%;
}


@media screen and (max-width: 600px) {
    .closeButton img {
        width: 16px;
        height: 16px;
    }

    .contentModal {
        padding: 0px 10px 10px 10px;
    }

    .label__title__modal {
        font-size: 18px;
    }

    .imgProfile {
        width: 50px;
        border-radius: 50%;
    }

    .modalController {
        width: 380px;
        height: auto;
        background: #ffffff;
        border-radius: 9px;
    }
}


