.Img {
    width: 120px;
}

@media screen and (max-width: 1100px) {
    .Img {
        width: 90px;
    }
}

@media screen and (max-width: 600px) {
    .Img {
        width: 50px;
    }
}